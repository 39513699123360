import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteComponent } from './autocomplete.component';
import { InputModule } from '../input/input.module';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';
import { SlidingContentModule } from '../sliding-content/sliding-content.module';
import { TriggerFocusModule } from '@util/directives/trigger-focus/trigger-focus.module';
import { I18nModule } from '@spartacus/core';



@NgModule({
  declarations: [
    AutocompleteComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    ActivateElementModule,
    SlidingContentModule,
    TriggerFocusModule,
    I18nModule
  ],
  exports: [
    AutocompleteComponent
  ]
})
export class AutocompleteModule { }
